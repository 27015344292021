import { i18n } from "../plugins/i18n.plugin";

export const validateEmail = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

export const hasDuplicate = <T>(elements: T[], field: keyof T, value?: T[keyof T]): boolean => {
  const fieldCount: Record<string, number> = {};

  if (value) {
    // Si se proporciona un valor específico, busca directamente
    return elements.some((element) => element[field] === value);
  } else {
    // Contar ocurrencias de cada valor en el campo especificado
    for (const element of elements) {
      const fieldValue = String(element[field]); // Convertir a string para usar como clave
      fieldCount[fieldValue] = (fieldCount[fieldValue] || 0) + 1;
      if (fieldCount[fieldValue] > 1) {
        return true; // Retorna true si encuentra duplicados
      }
    }
  }

  return false;
};

export const notifyValidateEmail = (rule: any, email: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (!email) {
        reject(new Error(`${i18n.global.t("validation.isRequired", { item: "email" })}`));
      } else if (!validateEmail(email)) {
        reject(new Error(`${i18n.global.t("validation.notCorrect", { item: "email" })}`));
      } else {
        resolve(); // El correo es válido
      }
    }, 500); // Simula una espera de 500 ms
  });
};

export const notifyValidateWhatsapp = (rule: any, whatsapp: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (whatsapp) {
        const cleanedWhatsapp = whatsapp.replace(/\D/g, "");

        if (cleanedWhatsapp.length < 10 || cleanedWhatsapp.length > 15) {
          reject(new Error(`${i18n.global.t("validation.notCorrect", { item: "whatsapp" })}`));
        } else if (isNaN(Number(cleanedWhatsapp))) {
          reject(new Error(`${i18n.global.t("validation.notCorrect", { item: "whatsapp" })}`));
        } else {
          resolve();
        }
      } else {
        reject(new Error(`${i18n.global.t("validation.isRequired", { item: "whatsapp" })}`));
      }
    }, 500);
  });
};
