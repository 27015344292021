
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      observer: undefined as undefined | IntersectionObserver,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    });

    if (this.observer) {
      this.observer.observe(this.$el);
    }
  },
  unmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
});
