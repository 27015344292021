
import { defineComponent, ref } from "vue";
import PageButtonsComponent from "@/components/PageButtons.component.vue";
import AlertFormComponent from "@/components/alerts/AlertForm.component.vue";
import AlertListComponent from "@/components/alerts/AlertList.component.vue";
import ContactFormComponent from "@/components/contacts/ContactForm.component.vue";
import { Alert } from "@/types/alert.type";
import { ElMessage } from "element-plus";

export default defineComponent({
  components: {
    PageButtonsComponent,
    AlertFormComponent,
    AlertListComponent,
    ContactFormComponent,
  },
  setup() {
    const contactForm = ref<any>();
    return { contactForm };
  },
  data() {
    return {
      currentView: 1,
      showMap: false,
      showAlertForm: false,
      alertForm: undefined as undefined | Alert,
      alertsList: [],
      showContactForm: false,
    };
  },
  methods: {
    async goToNextView() {
      console.log(
        await (this.$refs["alertForm"] as InstanceType<typeof AlertFormComponent>).validateEmails()
      );
      if (
        this.currentView === 2 &&
        (await !(
          this.$refs["alertForm"] as InstanceType<typeof AlertFormComponent>
        ).validateEmails())
      ) {
        ElMessage.error("Comprueba que los correos sean válidos");
      } else {
        this.currentView++;
      }
    },
    goToPreviousView() {
      this.currentView--;
    },
    openAlertFormDialog() {
      this.currentView = 1;
      this.showAlertForm = true;
    },

    closeAlertFormDialog() {
      this.showAlertForm = false;
      this.currentView = 1;
    },
    /**
     * DIALOGO CONTACTOS
     */
    openContactForm() {
      this.showContactForm = true;
    },
    saveAlertForm() {
      (this.$refs["alertForm"] as InstanceType<typeof AlertFormComponent>)
        .doSave()
        ?.then(() => {
          this.closeAlertFormDialog();
          (this.$refs["alertList"] as InstanceType<typeof AlertListComponent>).refreshAlert();
          ElMessage.success(this.$t("success.action"));
        })
        .catch(() => void 0);
    },

    /**
     * Botones
     */

    async saveChanges() {
      this.showContactForm = await this.contactForm?.saveChanges();
    },

    closeContactForm() {
      this.showContactForm = false;
    },
  },
});
