import axios from "./api";
import { responseHandlerDefault } from "./../utils/handler.util";
import { Contact } from "./../types/contact";

export const getContacts = (userId: number): Promise<Contact[]> => {
  return axios.get<Contact>(`/contact/${userId}`).then(responseHandlerDefault);
};

export const postContacts = (contacts: Contact[]): Promise<Contact[]> => {
  return axios.post<Contact>(`/contact/`, contacts).then(responseHandlerDefault);
};

export const deleteContacts = (contactsIds: number[]): Promise<Contact[]> => {
  return axios.delete(`/contact/`, { data: { contactsIds } }).then(responseHandlerDefault);
};
